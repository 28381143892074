<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="examinations"
              item-text="name"
              item-value="id"
              v-model="query.examinationId"
              label="Kíp thi"
              dense
              @change="
                getSubjects($event);
                handleQueryChange();
              "
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="subjects"
              item-text="fullName"
              v-model="query.subjectId"
              item-value="id"
              label="Học phần"
              @change="handleQueryChange"
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-btn
            :disabled="!examinationSubject"
            class="d-block mx-auto mt-5"
            color="blue"
            @click="addOfficersToExamination"
            >Lưu</v-btn
          >
          <v-col cols="12">
            <v-data-table
              disable-pagination
              v-model="selectedOfficers"
              hide-default-footer
              :headers="[
                { text: 'Tên', value: 'name' },
                { text: 'Email', value: 'email' }
              ]"
              height="calc(100vh - 250px)"
              :items="officers"
              show-select
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <h3 class="mt-3">Danh sách cán bộ ra đề</h3>
            <v-data-table
              class="mt-3"
              disable-pagination
              hide-default-footer
              :headers="[
                { text: 'Tên', value: 'name' },
                { text: 'Email', value: 'email' }
              ]"
              :items="currentOfficers"
              height="calc(100vh - 220px)"
            >
            </v-data-table
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { getSubjects, getExaminations } from "@/api/original-exam";
import {
  getOfficersFromExamination,
  addOfficersToExamination
} from "@/api/officer";
import { getOfficers } from "@/api/room";
export default {
  data() {
    return {
      subjects: [],
      examinations: [],
      query: {
        subjectId: "",
        examinationId: ""
      },

      officers: [],
      currentOfficers: [],
      selectedOfficers: [],
      examinationSubject: null
    };
  },
  methods: {
    async getSubjects(val) {
      const examination = this.examinations.find(item => item.id === val);
      this.subjects = examination.subjects;
    },
    async getExaminations() {
      const { data } = await getExaminations();
      this.examinations = data;
    },
    async getOfficers() {
      const { data } = await getOfficers();
      this.officers = data;
    },
    async getOfficersFromExamination() {
      this.currentOfficers = [];
      this.selectedOfficers = [];
      this.examinationSubject = null;
      const { data } = await getOfficersFromExamination(this.query);
      if (!data) {
        return this.$snackbar("Không có kíp thi môn học này", "error");
      }
      this.examinationSubject = data;
      this.currentOfficers = data.users;
      for (const officer of this.officers) {
        if (this.currentOfficers.find(item => item.email === officer.email)) {
          this.selectedOfficers.push(officer);
        }
      }
    },
    async addOfficersToExamination() {
      this.$loader(true);
      await addOfficersToExamination({
        ...this.query,
        officers: this.selectedOfficers
      });
      this.$loader(false);
      this.getOfficersFromExamination();
    },
    handleQueryChange() {
      if (this.query.subjectId && this.query.examinationId)
        this.getOfficersFromExamination();
    }
  },
  created() {
    this.getExaminations();
    this.getOfficers();
  }
};
</script>