var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.examinations,"item-text":"name","item-value":"id","label":"Kíp thi","dense":"","hide-details":""},on:{"change":function($event){_vm.getSubjects($event);
              _vm.handleQueryChange();}},model:{value:(_vm.query.examinationId),callback:function ($$v) {_vm.$set(_vm.query, "examinationId", $$v)},expression:"query.examinationId"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.subjects,"item-text":"fullName","item-value":"id","label":"Học phần","dense":"","hide-details":""},on:{"change":_vm.handleQueryChange},model:{value:(_vm.query.subjectId),callback:function ($$v) {_vm.$set(_vm.query, "subjectId", $$v)},expression:"query.subjectId"}})],1),_c('v-btn',{staticClass:"d-block mx-auto mt-5",attrs:{"disabled":!_vm.examinationSubject,"color":"blue"},on:{"click":_vm.addOfficersToExamination}},[_vm._v("Lưu")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":[
              { text: 'Tên', value: 'name' },
              { text: 'Email', value: 'email' }
            ],"height":"calc(100vh - 250px)","items":_vm.officers,"show-select":""},model:{value:(_vm.selectedOfficers),callback:function ($$v) {_vm.selectedOfficers=$$v},expression:"selectedOfficers"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Danh sách cán bộ ra đề")]),_c('v-data-table',{staticClass:"mt-3",attrs:{"disable-pagination":"","hide-default-footer":"","headers":[
              { text: 'Tên', value: 'name' },
              { text: 'Email', value: 'email' }
            ],"items":_vm.currentOfficers,"height":"calc(100vh - 220px)"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }